var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 900,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disabled } },
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "标题",
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["title", _vm.validatorRules.title],
                                expression: "['title', validatorRules.title]",
                              },
                            ],
                            attrs: {
                              placeholder: "请输入标题",
                              readOnly: _vm.disableSubmit,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "消息类型",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "msgCategory",
                                    _vm.validatorRules.msgCategory,
                                  ],
                                  expression:
                                    "[ 'msgCategory', validatorRules.msgCategory]",
                                },
                              ],
                              attrs: {
                                placeholder: "请选择消息类型",
                                disabled: _vm.disableSubmit,
                                getPopupContainer: (target) =>
                                  target.parentNode,
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("通知公告"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("系统消息"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "开始时间:",
                          },
                        },
                        [
                          _c("j-date", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "startTime",
                                  _vm.validatorRules.startTime,
                                ],
                                expression:
                                  "[ 'startTime', validatorRules.startTime]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              getCalendarContainer: (node) => node.parentNode,
                              placeholder: "请选择开始时间",
                              showTime: "",
                              dateFormat: "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticClass: "endTime",
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "结束时间",
                          },
                        },
                        [
                          _c("j-date", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["endTime", _vm.validatorRules.endTime],
                                expression:
                                  "[ 'endTime', validatorRules.endTime]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              getCalendarContainer: (node) => node.parentNode,
                              placeholder: "请选择结束时间",
                              showTime: "",
                              dateFormat: "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "优先级",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["priority", {}],
                                  expression: "[ 'priority', {}]",
                                },
                              ],
                              attrs: {
                                placeholder: "请选择优先级",
                                disabled: _vm.disableSubmit,
                                getPopupContainer: (target) =>
                                  target.parentNode,
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "L" } }, [
                                _vm._v("低"),
                              ]),
                              _c("a-select-option", { attrs: { value: "M" } }, [
                                _vm._v("中"),
                              ]),
                              _c("a-select-option", { attrs: { value: "H" } }, [
                                _vm._v("高"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "通告类型",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "msgType",
                                    _vm.validatorRules.msgType,
                                  ],
                                  expression:
                                    "[ 'msgType', validatorRules.msgType]",
                                },
                              ],
                              attrs: {
                                placeholder: "请选择通告类型",
                                disabled: _vm.disableSubmit,
                                getPopupContainer: (target) =>
                                  target.parentNode,
                              },
                              on: { change: _vm.chooseMsgType },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "USER" } },
                                [_vm._v("指定用户")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "ALL" } },
                                [_vm._v("全体用户")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "摘要",
                          },
                        },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "msgAbstract",
                                  _vm.validatorRules.msgAbstract,
                                ],
                                expression:
                                  "['msgAbstract',validatorRules.msgAbstract]",
                              },
                            ],
                            attrs: { placeholder: "请输入摘要" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 / 2 } },
                    [
                      _vm.userType
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "指定用户",
                              },
                            },
                            [
                              _c("a-select", {
                                attrs: {
                                  mode: "multiple",
                                  placeholder: "请选择用户",
                                  labelInValue: true,
                                },
                                on: {
                                  dropdownVisibleChange: _vm.selectUserIds,
                                  change: _vm.handleChange,
                                },
                                model: {
                                  value: _vm.selectedUser,
                                  callback: function ($$v) {
                                    _vm.selectedUser = $$v
                                  },
                                  expression: "selectedUser",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticClass: "j-field-content",
                          attrs: {
                            labelCol: _vm.labelColX1,
                            wrapperCol: _vm.wrapperColX1,
                            label: "内容",
                          },
                        },
                        [
                          _c("j-editor", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["msgContent", {}],
                                expression: "[ 'msgContent', {} ]",
                              },
                            ],
                            attrs: { triggerChange: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-user-list-modal", {
        ref: "UserListModal",
        on: { choseUser: _vm.choseUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }